@import url("https://fonts.googleapis.com/css2?family=Chivo&family=Lobster+Two:wght@700&display=swap");
.App {
  text-align: center;
  margin: 130px;
}

/* tumeric color   color: #e9a447; */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

* {
  font-family: "Chivo", sans-serif;
}

.square-image {
  margin: 10px;
  height: 400px;
  width: 400px;
}

.project-text-container {
  margin: auto;
  width: 50%;
}

.project-text-container-wide {
  margin: auto;
  width: 70%;
}

.project-text {
  text-align: left;
  line-height: 2rem;
}

/* NavBar Styling */

p {
  color: black;
}

h4 {
  display: flex;
  font-size: 50px;
  margin: 2px;
  margin-bottom: 20px;
  color: #89b288;
  letter-spacing: 1.3px;
  font-family: "Lobster Two", cursive;
}

h5 {
  font-size: 16px;
  display: flex;
  margin: 2px;
  color: #acafac;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  margin: 2px;
  margin-top: 2%;
}

.left-side-nav {
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.right-side-nav {
  display: flex;
}

.drop-container {
  transition: visibility 0s, opacity 0.3s linear;
}

.drop-button {
  display: flex;
  background-color: white;
  border: none;
  text-decoration: none;
}

.drop-button:hover {
  color: #2e382e;
}

.drop-button .nav-link {
  font-size: 20px;
  border: none;
  outline: none;
  color: #acafac;
  padding: 14px 0px;
  transition: color 0.1s ease-in;
}

.drop-content {
  visibility: hidden;
  opacity: 0;
  background-color: white;
  min-width: 160px;
  z-index: 1;
  margin: 2px;
  text-decoration: none;
  color: #acafac;
}

.drop-container:hover .drop-content {
  visibility: visible;
  flex-direction: column;
  opacity: 1;
}

.drop-content .nav-link {
  float: none;
  color: #acafac;
  padding: 12px 0px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.drop-content .nav-link:hover {
  color: black;
}

.nav-link {
  margin: 2px;
  text-decoration: none;
  color: #acafac;
  font-family: "Chivo", sans-serif;
}

.nav-link:hover {
  color: black;
}

/* Landing Page */

.landing-page-container {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-container {
  position: relative;
  display: inline-block;
}

.project-container .project-title {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  margin: 10px;
  background-color: #e9a447;
  height: 400px;
  width: 400px;
  transition: visibility 0s, opacity 0.3s;
}

.project-container:hover .project-title {
  visibility: visible;
  display: block;
  opacity: 0.9;
}

.project-link {
  margin-top: -10px;
  display: inline;
  opacity: 1;
  font-family: "Chivo", sans-serif;
  font-weight: 700;
  text-decoration: none;
  color: white;
  font-size: 65px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.error-page {
  margin: 20px;
}
/* Info Page  */

.info-div {
  width: 50%;
  text-align: left;
}

.info-cover-photo {
  margin-bottom: 3%;
}

/* Footer */

.social-media-container {
  margin-top: 20px;
  display: flex;
  flex: row;
  color: black;
  height: 20px;
  width: 20px;
}

.social-media-logo {
  margin-top: 80px;
  margin-right: 20px;
  color: black;
}

.mobile-nav {
  display: none;
}
/* put a super discreet gradient in the background */

@media (max-width: 1000px) {
  body {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .App {
    margin: 3% 5%;
  }

  .nav-container {
    display: none;
    margin: 5%;
  }

  .mobile-nav {
    display: block;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99;
  }

  .mobile-nav-closed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .left-side-nav {
    display: flex;
    flex-direction: column;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 0.5rem;
  }

  .close-icon {
    margin: 2%;
    width: 85%;
    display: flex;
    justify-content: flex-end;
  }

  .mobile-nav-open {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    height: 100%;
    width: 100%;
  }

  .nav-section {
    margin-top: 2%;
    padding: 5px;
    color: #acafac;
    text-decoration: none;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    margin: 1%;
  }

  .nav-link:hover {
    color: black;
  }
  .landing-page-container {
    margin-top: 20px;
  }

  .project-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project-text-container {
    width: 90%;
  }

  .project-text {
    line-height: 1.2;
    text-align: center;
  }

  .info-div {
    width: 100%;
  }

  .social-media-container {
    width: 100%;
    justify-content: center;
    margin-bottom: 5%;
  }

  .square-image {
    margin: 2%;
  }

}

